// 协议弹出框
// sle
<template>
  <div>
    <a-modal
      v-model:visible="visible"
      title="威智造服务协议书"
      :width="750"
      :footer="false"
      :maskClosable="false"
    >
      协议内容
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      spinning: false, // 是否加载
      visible: false, // 弹窗是否显示
    }
  },
  methods: {
    // 新增开票信息
    viewShow () {
      this.visible = true
    },
  },
})
</script>
