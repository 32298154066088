<template>
  <div class="main">
    <div v-if="invitation.result">
      <div class="remark" style="margin-top: 100px">
        {{ invitation.invitationPsn }}邀请你加入{{
          invitation.invitationEnterprise
        }}
      </div>
      <a-spin :spinning="spinning">
        <a-form
          class="user-layout-login"
          :model="formState"
          @finish="handleFinish"
        >
          <!-- <a-form-item :wrapper-col="{ span: 24 }">
            <a-input
              size="large"
              type="text"
              placeholder="手机号码"
              v-model:value="formState.phone"
              :maxlength="11"
            >
              <template #prefix>
                <MobileOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-row :gutter="16">
            <a-col class="gutter-row" :span="16">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-input
                  size="large"
                  type="text"
                  placeholder="验证码"
                  v-model:value="formState.checkCode"
                  :maxlength="6"
                >
                  <template #prefix>
                    <MailOutlined style="color: rgba(0, 0, 0, 0.25)" />
                  </template>
                </a-input>
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :span="8">
              <button
                class="getCaptcha"
                v-if="startCountDown"
                @click.stop.prevent="getCaptcha"
              >
                获取验证码
              </button>
              <div class="countDownTimer flex-centent" v-if="!startCountDown">
                {{ countDownNum }} 秒后重发
              </div>
            </a-col>
          </a-row>
          <a-form-item :wrapper-col="{ span: 24 }">
            <a-input
              size="large"
              type="text"
              placeholder="请填写真实姓名，方便大家与你联系"
              v-model:value="formState.name"
              :maxlength="11"
            >
              <template #prefix>
                <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 24 }">
            <a-input-password
              v-model:value="formState.password"
              type="password"
              size="large"
              placeholder="登录密码"
              :maxlength="18"
            >
              <template #prefix>
                <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input-password>
          </a-form-item>

          <a-form-item :wrapper-col="{ span: 24 }">
            <a-checkbox v-model:checked="protocolChecked">
              我已阅读并且同意
            </a-checkbox>
            <a @click="protocolClick">《威智造服务协议书》</a>
          </a-form-item>

          <a-form-item style="margin-top: 24px" :wrapper-col="{ span: 24 }">
            <a-button
              type="primary"
              html-type="submit"
              size="large"
              class="login-button"
              :loading="state.loginBtn"
              :disabled="!protocolChecked || state.loginBtn"
            >
              注册并加入
            </a-button>
          </a-form-item>

          <a-form-item :wrapper-col="{ span: 24 }">
            已有账号？
            <a @click="backLogin">返回登录</a>
          </a-form-item> -->
          <a-form-item style="margin-top: 104px" :wrapper-col="{ span: 24 }">
            <a-button
              type="primary"
              html-type="submit"
              size="large"
              class="login-button"
              :loading="state.loginBtn"
            >
              加入
            </a-button>
          </a-form-item>
        </a-form>
      </a-spin>
    </div>
    <div v-else class="lose-efficacy flex-column justify-around">
      {{ invitation.remark }}
      <a-button
        type="primary"
        size="large"
        block
        html-type="submit"
        class="login-button"
        @click="backLogin"
      >
        返回登录页
      </a-button>
    </div>
    <ProtocolView ref="protocolView" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
// import { LockOutlined, MobileOutlined, MailOutlined, UserOutlined } from '@ant-design/icons-vue'
import ProtocolView from '@/components/ProtocolView'
import api from '@/api/API'
const userId = '39fc90eb-91d4-2d9c-7efc-f6313d0d1488'

export default defineComponent({
  name: 'account-invite',
  components: {
    // UserOutlined,
    // LockOutlined,
    // MobileOutlined,
    // MailOutlined,
    ProtocolView,
  },
  data () {
    return {
      spinning: false, // 是否加载
      invitation: {
        invitationEnterprise: null,
        invitationPsn: null,
        remark: null,
        result: false,
        temporaryId: null,
      },
      protocolChecked: false,
      formState: {
        name: '',
        phone: '',
        password: '',
        checkCode: '',
        inviteKey: '',
        invitationWay: '',
      },
      state: {
        time: 60,
        loginBtn: false,
        loginType: 0,
        smsSendBtn: false,
      },
      protocolVisible: false,
      startCountDown: true,
      countDownNum: '', // 倒计时
      countDownTimer: '',
    }
  },
  created () {
    this.invitationVerification()
  },
  methods: {
    // 页面加载时验证
    invitationVerification () {
      this.spinning = true
      const query = this.$route.query
      api
        .post('/api/app/employee-invitation/invitation-link-verification', {
          timeStamp: query.inviteKey,
          invitationWay: query.way,
        })
        .then(({ data }) => {
          this.spinning = false
          this.invitation = data
          this.formState.inviteKey = query.inviteKey
          this.formState.invitationWay = query.way
        })
        .catch(err => {
          this.spinning = false
          this.invitation.remark = '当前链接已失效'
          console.log(err)
        })
    },
    // 确认加入
    handleFinish () {
      this.spinning = true
      const obj = {
        id: this.invitation.id,
        shortKey: '',
        // personId: userId,
        // phone: this.formState.phone,
        // checkCode: this.formState.checkCode,
        // password: this.formState.password,
        // userName: this.formState.name,
      }
      api
        .post('/api/app/employee-invitation/accept-invitation', obj)
        .then(({ data }) => {
          this.spinning = false
          this.$message.success('正在跳转')
          this.$router.push({ path: '/EnterpriseManagement' })
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // 获取验证码
    getCaptcha (e) {
      if (!this.formState.phone) {
        this.$message.error('请先填写手机号')
        return
      }
      const obj = {
        expireTime: 5,
        key: this.formState.phone,
      }
      api
        .post('/api/app/account/send-verifycation-code', obj)
        .then(({ data }) => {
          this.countDown()
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // 倒计时
    countDown () {
      const TIME_COUNT = 60
      this.countDownNum = TIME_COUNT
      this.startCountDown = false
      this.countDownTimer = setInterval(() => {
        if (this.countDownNum > 0 && this.countDownNum <= TIME_COUNT) {
          this.countDownNum--
        } else {
          this.startCountDown = true
          clearInterval(this.countDownTimer)
          this.countDownTimer = null
        }
      }, 1000)
    },
    // 返回登录页
    backLogin () {
      this.$router.push({ path: '/account/login' })
    },
    // 协议按钮
    protocolClick () {
      this.$refs.protocolView.viewShow()
    },
  },
})
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.ant-form-item-control-wrapper {
  width: 100% !important;
}
.remark {
  font-size: 16px;
  margin: 30px 0;
}
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .countDownTimer {
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
.lose-efficacy {
  height: 300px;
  font-size: 25px;
}
</style>
